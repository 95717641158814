const theme = {
  colors: {
    //FullSite
    fullSiteText: "#545454",
    fullSiteBackground: "#F6F6F4",
    fullSiteWhite: "#FFFFFF",
    fullSiteGeography: "#E6E5E4",
    fullSiteButtonMenuText: "#8E8E8E",
    fullSiteGrey: "#707070",
    fullSiteTextBlack: "#3F3A37",
    fullSiteBackgroundColor: "#FDFBF7",
    fullSiteFooterLinkGrey: "#C1C1C1",
    //Part2
    part2SectionGrey: "#A3A199",
    part2TextLarge: "#545454",
    part2Header: "#6BB219",
    part2AnnotationsRed: "#F73939",
    part2AnnotationBlue: "#258CCC",
    part2NavigationGrey: "#545454",
    part2NavigationGrey2: "#666666",
    //Part1
    part1IntroSectionOpener: "#FFB809",
    part1IntroAnnotations: "#886DA3",
    //Part1CEQR
    part1CEQRBlueDark: "#0061a4",
    part1CEQRBlue2: "#258CCC",
    part1CEQRSectionOpener: "#57A1D8",
    part1CEQRBlueLight: "#57AAD8",
    //Part1CEQR2
    part1CEQR2PurpleDark: "#4f2B7A",
    part1CEQR2Purple2: "#613B8C",
    part1CEQR2SectionOpener: "#9275C3",
    part1CEQR2PurpleLight: "#886DA3",
    //Part1CEQR3
    part1CEQR3PinkDark: "#E00303",
    part1CEQR3Pink2: "#F73939",
    part1CEQR3SectionOpener: "#F97B75",
    part1CEQR3PinkLight: "#F97B75",
    //Part2Conclusion
    part2ConclusionGreen: "#6BB219",
    part2ConclusionGreen2: "#A6C644",
  },
  spacing: [0, 4, 8, 16, 32, 64],
  fonts: {
    urwDin: "urw-din, sans-serif",
    ibmPlex: "ibm-plex-mono, sans-serif",
  },
  fontWeight: {
    regular: 400,
    bold: 700,
  },
  fontSizes: [10, 11, 12, 14, 18, 24, 28, 32, 36, 58, 90],
  typography: {
    sectionReference: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "40px",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "1.2",
      textTransform: "uppercase",
    },
    sectionReference2: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "44px",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "1.2",
      textTransform: "uppercase",
    },
    sectionTitle: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "96px",
      fontWeight: "700",
      fontStyle: "normal",
      lineHeight: "1.1",
    },
    sectionTitle2: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "100px",
      fontWeight: "700",
      fontStyle: "normal",
      lineHeight: "1.1",
    },
    sectionHeadTitle: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "46px",
      fontWeight: "700",
      fontStyle: "normal",
      lineHeight: "1.1",
    },
    sectionHeadTitle2: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "78px",
      fontWeight: "700",
      fontStyle: "normal",
      lineHeight: "1.1",
    },
    header: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "40px",
      fontWeight: "600",
      fontStyle: "normal",
      lineHeight: "1.1",
    },
    introParagraph: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "22px",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "1.2",
    },
    introParagraph2: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "44px",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "1.2",
    },
    devSubHead: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "26px",
      fontWeight: "700",
      fontStyle: "normal",
      lineHeight: "1.4",
      color: "#545454"
    },
    subHead2: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "44px",
      fontWeight: "700",
      fontStyle: "normal",
      lineHeight: "1.1",
    },
    devParagraph: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "24px",
      fontWeight: "300",
      fontStyle: "normal",
      lineHeight: "1.4",
      color:"#545454",
    },
    paragraph2: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "32px",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "1.5",
    },
    sidep: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "16px",
      fontWeight: "300",
      fontStyle: "normal",
      lineHeight: "1.3",
      color: "#494845",
    },
    sideBold: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "16px",
      fontWeight: "600",
      fontStyle: "normal",
      lineHeight: "1.3",
      color: "#494845",
    },
    sideItal: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "16px",
      fontWeight: "300",
      fontStyle: "italic",
      lineHeight: "1.3",
      color: "#494845",
    },
    sideCaption: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "12px",
      fontWeight: "300",
      fontStyle: "normal",
      lineHeight: "1.3",
      color: "#494845",
    },
    //for the body of dev101
    methodsParagraph: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "24px",
      fontWeight: "300",
      lineHeight: "1.4",
      color: "#908E8E",
    },
    //variates below don't seem to be accounted for in the new design notes
    legendTitle: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "20px",
      fontWeight: "700",
      lineHeight: "1.2",
      color: "#545454",
    },
    legendSubTitle: {
      fontFamily: "urw-din, sans-serif",
      color: "#545454",
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "1.2",

    },
    legendText: {
      fontFamily: "urw-din, sans-serif",
      color: "#494845",
      fontSize: "16px",
      fontWeight: "300",
      lineHeight: "1.2",
    },
    legendSubText: {
      fontFamily: "urw-din, sans-serif",
      color: "#494845",
      fontSize: "12px",
      fontWeight: "300",
      lineHeight: "1.2",
    },
    legendSubDescription: {
      fontFamily: "urw-din, sans-serif",
      color: "#494845",
      fontSize: "14px",
      fontWeight: "300",
      lineHeight: "1.2",
    },
    navigationText: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "24px",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "1.2",
    },
    annotation: {
      fontFamily: "ibm-plex-mono, sans-serif",
      fontSize: "24px",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "1.2",
    },
    annotation2: {
      fontFamily: "ibm-plex-mono, sans-serif",
      fontSize: "32px",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "1.2",
    },
    captions: {
      fontFamily: "urw-din, sans-serif",
      fontSize: "14px",
      fontWeight: "300",
      fontStyle: "normal",
      lineHeight: "1.2",
      textAlign: "left",
    },
    captions2: {
      fontFamily: "ibm-plex-mono, sans-serif",
      fontSize: "18px",
      fontWeight: "400",
      fontStyle: "normal",
      lineHeight: "1.2",
    },
  },
  breakpoints: ["600px", "900px", "1200px", "1536px"],
};

export default theme;
